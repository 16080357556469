import * as React from 'react';
import { PageProps } from 'gatsby';

import Seo from '../components/seo';
import Layout from '../components/layout';
import PostList from '../container/post-list';

const Home: React.FC<PageProps> = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo />
      <PostList />
    </Layout>
  );
};

export default Home;
